<style type="text/css">
@import "~@/assets/css/common.css";

.form-control {
  padding: unset;
  border-radius: unset;
}

.form-control input {
  border-radius: unset;
  border: unset;
}
</style>
<style type="text/css" scoped>
/deep/.el-input__inner {
  height: 100%;
}
</style>
<script>
import $ from "jquery";
import LoginLeft from "@/views/pages/account/login-left";
import LoginTop from "@/views/pages/account/login-top";
import {
  getcode,
  listenEnterEv,
  sendPhoneCode,
  checkCode,
  checkMobileCode,
  codeExpired,
  findPasswordPhoneCode,
  findPassword
} from "@/api/index.js";
export default {
  components: {
    LoginLeft,
    LoginTop,
  },
  data() {
    const checkIdcard = (rule, value, cb) => {
      const regIdcard =
        /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
      if (regIdcard.test(value)) {
        return cb();
      }
      cb(new Error("请输入正确的身份证号"));
    };
    const checkMobile = (rule, value, cb) => {
      var regex =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/g;
      if (regex.test(value)) {
        return cb();
      }
      cb(new Error("请输入正确的手机号"));
    };
    const checkPassword = (rule, value, cb) => {
      const Password =
        /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{6,16}$/;
      if (Password.test(value)) {
        return cb();
      }
      cb(new Error("密码格式不正确(6-12位数字字母组合)"));
    };
    const checkPassword_again = (rule, value, cb) => {
      const regPassword = this.formDataforget2.mm;
      if (regPassword == value) {
        return cb();
      }
      cb(new Error("两次密码不相同"));
    };
    return {
      baseConfig: {},
      isShow: false, //验证码窗口
      hasGetCode: 0, //是否获取过一次验证码
      isForget: true, //是否显示找回密码部分
      imgCode: {
        uuid: "",
        img: "",
        code:""
      },
      formDataforget: {
        xm: "",
        yhzh: "",
        yddh: "",
        phoneCode: "",
      },
      formDataforget2: {
        xm: "",
        sfzjh: "",
        mm: "",
        mm_again: "",
      },
      rules: {
        xm: [
          {
            required: true,
            message: "请输入您的姓名",
            trigger: "blur",
          },
        ],
        yhzh: [
          {
            required: true,
            message: "请输入用户账号",
            trigger: "blur",
          },
        ],
        yddh: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            validator: checkMobile,
            trigger: "blur",
          },
        ],
        phoneCode: [
          {
            required: true,
            message: "请输入手机验证码",
            trigger: "blur",
          },
        ],
      },
      rules2: {
        mm: [
          {
            required: true,
            message: "请输入您的密码",
            trigger: "blur",
          },
          {
            validator: checkPassword,
            trigger: "blur",
          },
        ],
        mm_again: [
          {
            required: true,
            message: "请再次输入您的密码",
            trigger: "blur",
          },
          {
            validator: checkPassword_again,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    getImageCode: function () {
      var _this = this;
      getcode().then((res) => {
        if (res.status) {
          _this.imgCode.uuid = res.data.uuid;
          _this.imgCode.img = res.data.img;
        }
      });
    },
    checkLogin: function () {
      this.$refs["formDataforget"].validate((valid) => {
        if (valid) {
          if (!this.hasGetCode) {
            this.$notify({
              title: "失败",
              message: "请先获取手机验证码",
              type: "error",
            });
            return;
          }
          checkMobileCode({
            xm: this.formDataforget.xm,
            yhzh: this.formDataforget.yhzh,
            mobilePhone: this.formDataforget.yddh,
            code: this.formDataforget.phoneCode,
          }).then((res) => {
            if (res.status) {
              this.isForget = false; //显示设置密码页面
            }
          });
        }
      });
    },
    checkImagecode: function () {
      let uuid = this.imgCode.uuid;
      let code = this.imgCode.code;
      if (code) {
        this.getCode();
      } else {
        this.$notify({
          title: "失败",
          message: "请先输入图片验证码",
          type: "error",
        });
        $("#code").focus();
        this.getImageCode();
      }
    },

    /*获取验证码*/
    getCode() {
      var _this = this;
      if (this.imgCode.code && !this.committingPhoneCode) {
        /*如果状态正确*/
        var formData = {
          xm: this.formDataforget.xm,
          yhzh: this.formDataforget.yhzh,
          mobilePhone: this.formDataforget.yddh,
          uuid: this.imgCode.uuid,
          code: this.imgCode.code,
        };
        this.committingPhoneCode = true;
        findPasswordPhoneCode(formData).then((res) => {
          if (res.status) {
            _this.resetCode(60); //倒计时
            _this.$notify({
              title: "成功",
              message: res.message,
              type: "success",
            });
            this.isShow = false;
            this.hasGetCode = 1;
          } else {
            // this.imgCodeChange();
            _this.resetCode(5);
          }
        });
      } else {
        $("#phone").focus();
      }
    },
    //验证手机号码
    checkPhone: function () {
      let error = "";
      this.$refs["formDataforget"].validateField(
        ["yddh", "yhzh", "xm"],
        (valid) => {
          if (valid) {
            error = valid;
          }
        }
      );
      if (!error && !this.committingPhoneCode) {
        codeExpired({ mobilePhone: this.formDataforget.yddh }).then((res) => {
          if (res.status) {
            if (res.data) {
              this.isPast = true;
              this.isShow = true;
              this.getImageCode();
            } else {
              this.isPast = false;
              this.isShow = true;
              this.getImageCode();
            }
          }
        });
      }
    },
    //倒计时
    resetCode: function (second) {
      var timer = null;
      var _this = this;
      timer = setInterval(function () {
        second -= 1;
        if (second > 0) {
          $("#code_button").css("cursor", "no-drop");
          $("#code_button").text(second + "秒");
          $("#code_button").attr("disabled", "disabled");
        } else {
          clearInterval(timer);
          $("#code_button").css("cursor", "pointer");
          $("#code_button").text("发送验证码");
          $("#code_button").removeAttr("disabled", "disabled");
          _this.committingPhoneCode = false;
        }
      }, 1000);
    },
    // 找回密码
    checkForm: function () {
      this.$refs["formDataforget2"].validate((valid) => {
        if (valid) {
          var formData = {
            xm: this.formDataforget.xm,
            yhzh: this.formDataforget.yhzh,
            mm: this.formDataforget2.mm,
            yddh: this.formDataforget.yddh,
            phoneCode: this.formDataforget.phoneCode,
          };
          findPassword(formData).then((res) => {
            if (res.status) {
              this.$notify({
                title: "成功",
                message: "找回密码成功",
                type: "success",
              });
              this.$router.push("/login");
            }
          });
          return false;
        }
      });
    },
  },
  mounted() {
    listenEnterEv(this.checkLogin);
  },
  created() {
    let conf = JSON.parse(window.localStorage.getItem("BaseConfig"));
    this.baseConfig = conf ? conf : {};
  },
};
</script>

<template>
  <div>
    <div class="login-header">
      <div class="wp">
        <div class="p13">
          <LoginTop />
          <div class="login-bottom mt-4 d-flex">
            <LoginLeft />
            <div class="log-r" v-if="isForget">
              <p class="log-tips">找回密码</p>
              <div class="mt-4 login-main">
                <el-form
                  :model="formDataforget"
                  :rules="rules"
                  ref="formDataforget"
                >
                  <el-form-item prop="xm">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span
                          id="validationTooltipUsernamePrepend"
                          class="input-group-text icon-box"
                          ><img
                            src="@/assets/images/person/icon/user.png"
                            alt=""
                        /></span>
                      </div>
                      <el-input
                        type="text"
                        placeholder="请输入您的姓名"
                        aria-describedby="validationTooltipUsernamePrepend"
                        class="form-control h-100"
                        v-model="formDataforget.xm"
                      />
                    </div>
                  </el-form-item>
                  <el-form-item prop="sfzjh">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span
                          id="validationTooltipUsernamePrepend"
                          class="input-group-text icon-box"
                          ><img
                            src="@/assets/images/person/icon/idcard.png"
                            alt=""
                        /></span>
                      </div>
                      <el-input
                        type="text"
                        placeholder="请输入用户账号"
                        aria-describedby="validationTooltipUsernamePrepend"
                        class="form-control h-100"
                        v-model="formDataforget.yhzh"
                      />
                    </div>
                  </el-form-item>
                  <el-form-item prop="yddh">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span
                          id="validationTooltipUsernamePrepend"
                          class="input-group-text icon-box"
                          ><img
                            src="@/assets/images/person/icon/phone.png"
                            alt=""
                        /></span>
                      </div>
                      <el-input
                        id="phone"
                        type="text"
                        placeholder="请输入手机号码"
                        aria-describedby="validationTooltipUsernamePrepend"
                        class="form-control h-100"
                        v-model="formDataforget.yddh"
                      />
                    </div>
                  </el-form-item>

                  <el-form-item prop="phoneCode">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span
                          id="validationTooltipUsernamePrepend"
                          class="input-group-text icon-box"
                          ><img
                            src="@/assets/images/person/icon/sms.png"
                            alt=""
                        /></span>
                      </div>
                      <el-input
                        type="text"
                        placeholder="请输入短信码"
                        aria-describedby="validationTooltipUsernamePrepend"
                        class="form-control h-100"
                        v-model="formDataforget.phoneCode"
                      />
                      <div>
                        <b-button
                          id="code_button"
                          variant="info"
                          class="h-100 verification-code"
                          @click="checkPhone"
                        >
                          获取短信码
                        </b-button>
                        <b-modal
                          id="register"
                          v-model="isShow"
                          title="获取验证码"
                          title-class="font-18"
                          centered
                          class="person-modal"
                          hide-footer
                          style="width: 395px"
                        >
                          <div class="input-group" style="height: 45px">
                            <div class="input-group-prepend">
                              <span
                                id="validationTooltipUsernamePrepend"
                                class="input-group-text icon-box"
                                ><img
                                  src="@/assets/images/person/icon/user.png"
                                  alt=""
                              /></span>
                            </div>
                            <el-input
                              type="text"
                              id="code"
                              placeholder="请输入验证码"
                              aria-describedby="validationTooltipUsernamePrepend"
                              class="form-control h-100"
                              v-model="imgCode.code"
                            />
                            <img
                              :src="imgCode.img"
                              class="check-img"
                              @click="getImageCode"
                            />
                          </div>
                          <div class="w-100 mt-2">
                            <button
                              type="button"
                              class="btn btn-info change-btn"
                              @click="checkImagecode"
                            >
                              获取短信验证码
                            </button>
                          </div>
                        </b-modal>
                      </div>
                    </div>
                  </el-form-item>
                  <div class="login-btn">
                    <button
                      type="button"
                      class="btn btn-info w-100"
                      @click="checkLogin"
                    >
                      下一步，设置密码
                    </button>
                  </div>
                  <div class="text-center mt-2">
                    <a href="/login" class="text-dark">返回登录界面</a>
                  </div>
                </el-form>
              </div>
            </div>
            <div class="log-r" v-else>
              <p class="log-tips">设置密码</p>
              <div class="mt-4 login-main">
                <el-form
                  :model="formDataforget2"
                  :rules="rules2"
                  ref="formDataforget2"
                >
                  <el-form-item prop="xm">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span
                          id="validationTooltipUsernamePrepend"
                          class="input-group-text icon-box"
                          ><img
                            src="@/assets/images/person/icon/user.png"
                            alt=""
                        /></span>
                      </div>
                      <el-input
                        type="text"
                        placeholder="姓名"
                        id="name"
                        aria-describedby="validationTooltipUsernamePrepend"
                        class="form-control h-100"
                        readonly
                        v-model="formDataforget.xm"
                      />
                    </div>
                  </el-form-item>
                  <el-form-item prop="yhzh">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span
                          id="validationTooltipUsernamePrepend"
                          class="input-group-text icon-box"
                          ><img
                            src="@/assets/images/person/icon/idcard.png"
                            alt=""
                        /></span>
                      </div>
                      <el-input
                        type="text"
                        placeholder="用户账号"
                        aria-describedby="validationTooltipUsernamePrepend"
                        class="form-control h-100"
                        readonly
                        v-model="formDataforget.yhzh"
                      />
                    </div>
                  </el-form-item>
                  <el-form-item prop="mm">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span
                          id="validationTooltipUsernamePrepend"
                          class="input-group-text icon-box"
                          ><img
                            src="@/assets/images/person/icon/lock.png"
                            alt=""
                        /></span>
                      </div>
                      <el-input
                        id="password"
                        type="password"
                        placeholder="请设置新的密码"
                        aria-describedby="validationTooltipUsernamePrepend"
                        class="form-control h-100"
                        v-model="formDataforget2.mm"
                      />
                    </div>
                  </el-form-item>
                  <el-form-item prop="mm_again">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span
                          id="validationTooltipUsernamePrepend"
                          class="input-group-text icon-box"
                          ><img
                            src="@/assets/images/person/icon/completed.png"
                            alt=""
                        /></span>
                      </div>
                      <el-input
                        type="password"
                        placeholder="请重新输入一次新密码"
                        aria-describedby="validationTooltipUsernamePrepend"
                        class="form-control h-100"
                        v-model="formDataforget2.mm_again"
                      />
                    </div>
                  </el-form-item>
                  <div class="login-btn">
                    <button
                      type="button"
                      class="btn btn-info w-100"
                      @click="checkForm"
                    >
                      确认设置密码
                    </button>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
          <div class="login-footer text-center mt-4">
            版权所有：{{ baseConfig.WebsiteName }}
<!--            ©            {{ baseConfig.SystemYear }} Rights Reserved-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
